const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export class ScarcityCountdown {
	init(lines: HTMLCollectionOf<HTMLElement>) {
		for (const line of lines) {
			const posixTimestamp = line.dataset.activeUntil;
			if (posixTimestamp) {
				const end = new Date(posixTimestamp);

				const intervalId = window.setInterval(() => {
					this.setCountdownOrExpiryText(new Date(), end, line, intervalId);
				}, 500);
				this.setCountdownOrExpiryText(new Date(), end, line, intervalId);
			}
		}
	}

	setCountdownOrExpiryText(now: Date, end: Date, preline: HTMLElement, intervalId: number) {
		const time_range_in_ms = end.getTime() - now.getTime();
		if (time_range_in_ms < ONE_DAY && time_range_in_ms > ONE_SECOND) {
			const hours = Math.floor((time_range_in_ms % ONE_DAY) / ONE_HOUR);
			const minutes = Math.floor((time_range_in_ms % ONE_HOUR) / ONE_MINUTE);
			const seconds = Math.floor((time_range_in_ms % ONE_MINUTE) / ONE_SECOND);

			const hours_string = hours > 0 ? `${hours}h ` : "";
			const minutes_string = minutes > 0 ? `${minutes}m ` : "";
			const seconds_string = seconds > 0 ? `${seconds}s ` : "";

			preline.innerHTML = `nur noch ${hours_string}${minutes_string}${seconds_string}`;
		} else if (time_range_in_ms <= ONE_SECOND) {
			window.clearInterval(intervalId);
			window.setTimeout(() => {
				preline.innerHTML = "leider abgelaufen";
			}, 1000);
		} /*                                                                                             */
	}
}
